<template>
	<div class="wrap">
		<div class="tit">
			<h4>微信绑定</h4>
			<div class="tit-right">
				<router-link to="">把自己的微信二维码展示到店铺里<i class="el-icon-arrow-right" /></router-link>
				<router-link to="">如何绑定微信<i class="el-icon-arrow-right" /></router-link>
			</div>
		</div>
		<div class="content">
			<div class="tip" v-if="openid">
				<div class="user">
					<img :src="headimg">
					<p>{{nickname}}</p>
				</div>
				<div class="right">
					<span>已绑定</span>
					<div @click="unBindWx">解除绑定</div>
				</div>
			</div>
			<template v-else>
				<div class="tip">
					<p>您目前没有绑定微信号，请完成绑定，绑定成功激活获得30积分！</p>
					<div class="tip-btn" @click="handleBindWx">立即绑定</div>
				</div>
				<div class="banner">
					<div class="twoma">
						<img :src="bangImg">
					</div>
				</div>
			</template>
			<Bindwx />
		</div>
	</div>
</template>

<script>
	import Bindwx from '@/components/Bindwx'
	import Axios from 'axios'
	export default {
		data() {
			return {
				timer: null,
				openid: '',
				bangImg: '',
				headimg: '',
				nickname: ''
			}
		},
		created() {
			if (this.$store.state.WX_STATE == 1) {
				this.b()
			} else {
				this.b('yes')
				this.a()
			}

		},
		destroyed() {
			if (this.timer) {
				clearInterval(this.timer)
			}
		},
		methods: {
			a() {
				var that = this
				responseType: 'blob'
				this.timer = setInterval(() => {
					that.b('no')
				}, 3000)
			},
			b(state) {
				this.$axios.post('/bandWechat', {}, {
					loading: state
				}).then(data2 => {
					this.openid = data2.openid
					if (!this.bangImg) {
						let bangImg = data2.bangImg;
						this.bangImg = bangImg
					}
					if (!this.openid) {
						return
					}
					if (this.openid) {
						if (data2.openid == 400) {
							let params = {
								user_id: ""
							}
							this.$axios.post('/bandClear', params, {})
							this.$message.error('该微信已绑定其他账号，请更换微信扫码绑定！');
						} else if (data2.openid.length > 20 || data2.openid != 400) {
							this.openid = data2.openid
							this.headimg = data2.headimg
							this.nickname = data2.nickname
							this.$message.success('绑定成功！');
							this.$store.commit('SET_WX_STATE', 1)
							clearInterval(this.timer)
						}

					}

				})
			},
			handleBindWx() {
				this.$alert('请使用微信扫描下方二维码完成绑定')
			},
			unBindWx() {
				this.$confirm('此操作将解绑当前微信, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios.post('/outbandWechat').then(data => {
						if (data == 1) {
							this.$message.success('解绑成功!')
							this.$store.commit('SET_WX_STATE', 0)
						} else {
							this.$message.warning('出现了一点小问题，请联系客户~')
						}
					})
				}).catch(() => {})
			}
		},
		components: {
			Bindwx
		}
	}
</script>

<style scoped>
	.wrap{
		width: 1600px;
		background-color: #fff;
	}
	body{
	  min-width:1920px;
	}
	.el-message {
		top: 200px !important;
		z-index: 99999 !important;
	}

	.tit {
		height: 77px;
		display: flex;
		align-items: center;
		padding: 0 28px;
		border-bottom: 1px solid #ebebeb;
	}

	.tit h4 {
		font-size: 18px;
	}

	.tit::before {
		content: '';
		width: 4px;
		height: 20px;
		background: #0f69dc;
		margin-right: 11px;
	}

	.tit-right {
		margin-left: auto;
		text-align: right;
		font-size: 12px;
	}

	.tit-right a {
		display: block;
		line-height: 21px;
	}

	.tit-right a:nth-of-type(1) {
		color: #0f69dc;
	}

	.tit-right a:nth-of-type(2) {
		color: #f8721b;
		text-decoration: underline;
	}

	.content {
		margin: 0 28px;
	}

	.tip {
		display: flex;
		background: #ecf4ff;
		height: 55px;
		align-items: center;
		margin: 10px 0 9px;
		border-radius: 5px;
		justify-content: space-between;
		padding: 0 16px 0 30px;
	}

	.tip p {
		font-size: 14px;
		color: #666666;
	}

	.tip-btn {
		font-size: 12px;
		color: #fff;
		width: 70px;
		height: 28px;
		border: 1PX solid #ebebeb;
		background: #0194fe;
		border-radius: 5px;
		line-height: 28px;
		text-align: center;
		cursor: pointer;
	}

	.banner {
		height: 432px;
		background: url(../assets/img/bindwx-bg.png);
		background-size: 100% 100%;
		position: relative;
	}

	.twoma {
		width: 195px;
		height: 195px;
		position: absolute;
		right: 235px;
		top: 113px;
		border-radius: 13px;
	}

	.user {
		display: flex;
		align-items: center;
	}

	.user img {
		width: 30px;
		height: 30px;
		margin-right: 15px;
	}

	.right {
		margin-left: auto;
		display: flex;
		font-size: 12px;
		align-items: center;
	}

	.right span {
		color: #666666;
	}

	.right div {
		width: 70px;
		height: 28px;
		background: #fff;
		border: 1PX solid #ebebeb;
		text-align: center;
		line-height: 28px;
		color: #0f69dc;
		border-radius: 5px;
		margin-left: 26px;
		cursor: pointer;
	}
</style>
